<template>
  <div class="box-layout">
    <HeaderFiveEpitonet>
      <img slot="logo" src="../../assets/img/logo/global-logo.jpg" />
    </HeaderFiveEpitonet>

    <!-- Start Portfolio Details Area -->
    <div class="rf-portfolio-details section-ptb-xl">
      <v-container class="container">
        <v-row>
          <v-col sm="6" cols="12" class="pr--60 pr-md-15 pr-sm-15">
            <div class="rp-port-thumb">
              <div class="port-single-thumb">
                <img
                  src="../../assets/img/567x600px/567x600px-Melyepites.jpg"
                  alt="portfolio"
                />
              </div>

              <div class="port-single-thumb mt--30">
                <img
                  src="../../assets/img/567x600px/567x600px-Aszfalt.jpg"
                  alt="portfolio"
                />
              </div>

              <div class="port-single-thumb mt--30">
                <img
                  src="../../assets/img/567x600px/567x600px-Terko.jpg"
                  alt="portfolio"
                />
              </div>

              <div class="port-single-thumb mt--30">
                <img
                  src="../../assets/img/567x600px/567x600px-Parkolo.jpg"
                  alt="portfolio"
                />
              </div>


             
            </div>
          </v-col>
          <v-col sm="6" cols="12">
            <div class="rp-port-details-inner sticky-top">
              <div class="content">
                <h3>Mélyépítés</h3>
                <p>
                  Gazdaságos és ökológiai szempontból fenntartható megoldásokat kínálunk. Ez vonatkozik a földmunkákra, a víz- és az alapépítésre, valamint az ellátó és elvezető hálózatok, a vízi utak, a duzzasztógátak és a csatornák építésére is. 
                </p>
                <h4>Aszfaltozás:</h4>
                <p>Vállalunk aszfaltozást intézmények részére is. Legfőbb célunk, hogy a legjobb minőségű aszfalttal álljunk rendelkezésére, hogy igazán elégedett legyen szolgáltatásainkkal! </p>
                <h4>Útépítés:</h4>
                <p></p>
                <h4>Gépi földmunka:</h4>
                <p>Saját gépeinkkel alkalmasak vagyunk gépi és kézi földmunkákra, gépi és kézi bontási munkákra. </p>
                <h4>Térkövezés:</h4>
                <p>A térkövezés legelső lépése a leendő munkaterület felmérése. Ennél a folyamatnál fontos szempont a várható terhelés illetve időjárási viszonyok szem előtt tartása. Természetesen ez nem befolyásolja az ügyfél elképzeléseit, kívánságát. 

A térkő munkaterületeinek szakszerű méretezése már a mi feladatunk. Itt kerülhet sor a térkövezési munka látványtervének elkészítésére. A következő lépés a térkövezési tükör kiszedése, szintezése. Ez után következik a munkaterület alapzatának elkészítése a várható terhelésnek és a csapadék elvezető képességének megfelelően, majd az útalap szegélyek betonágyba helyezése. 

Majd a térkő alapzatául szolgáló ckt, sovány beton elterítése, tömörítése lapvibrátorral. Fontos folyamat a térkő felfagyását meggátló, finom sóder, kvarchomok, kövező murva elterítése. A térkövek lerakásánál, különös gondot fordítunk a szegélyező kövek szakszerű elhelyezésére, darabolása. 

Legutolsó lépésben a térkő fugáinak kitöltése mosott kvarchomokkal, majd végezetül jön a gumitalpas lejárás. </p>
                <h4>Parkoló építése:</h4>
                <p>Vállalunk parkoló kialakítását is.  </p>

              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Portfolio Details Area -->

    <Footer />
  </div>
</template>

<script>
  import HeaderFiveEpitonet from "../../components/header/HeaderFourEpitonet";
  import Footer from "../../components/footer/Footer";
  export default {
    components: {
      HeaderFiveEpitonet,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>

<style lang="scss" scoped>
  .portfolio-details-page .-rn-footer {
    background: #f9f9f9;
  }
</style>
